<template>

	<div id="servicos" v-if="dataReady">
		
		<div class="page-header container-fluid text-center d-flex align-items-center justify-content-center bg-servicos" 
		:style="[ mainBG ? { backgroundImage: `url(${cockpitUrl+'/'+servico.main_image.path})` } : null ]"
		>
			<div class="page-header-content">
				<h1 class="text-line-1 mb10" v-html="this.firstWordBold(servico.title)"></h1>
				<h2 class="text-line-2">{{ servico.subtitle }}</h2>
			</div>
			<div class="gradient-bottom"></div>
		</div>

		<div id="servicos-lista" class="container mb200">

			<div class="row mb100" v-for="content in servico.content" :key="content.value.titulo" v-bind:class="{'servico-com-foto': content.value.imagem }">
				<div class="col-10 col-lg-5 p-0" v-bind:class="{'servico-img': content.value.imagem, 'servico-img-empty': !content.value.imagem }">
					<img :src="cockpitUrl+'/'+content.value.imagem.path" v-if="content.value.imagem" style="width:100%">
				</div>
				<div class="servico-txt col-10 col-lg-5">
					<h3>{{ content.value.titulo }}</h3>
					<p>{{ content.value.texto }}</p>
				</div>
				

			</div>

		</div>

		<h1 class="mt100 mb200 text-center" v-if="!servico.content">Brevemente...</h1>

		<MainFooter />

	</div>

</template>

<script>
import globals from '../config/globals';
import {bus} from '@/main';
import MainFooter from "@/components/footer.vue";

export default {
	name: "servicos",
	components: {
		MainFooter
	},
	data() {
		return {
			servicoID: this.$route.params.id,
			servicoSlug: this.$route.params.slug,
			cockpitUrl: globals.BASE_URL,
			dataReady: false,
			mainBG: false,
			servico: null
		};
	},
	beforeCreate: function () {
		document.body.className = 'pag-servicos';
	},	
	mounted() {
		console.log('servico mounted');
		bus.$on('data-done', this.montarServico);
		if(this.$parent.dataReady) { this.montarServico(); }
	},
	methods: { 
		changeMetaData() {
			this.$parent.metaTitle = this.servico.title;
			this.$parent.metaDescription = this.servico.subtitle;
		},
		firstWordBold(content) {
			var text = content.trim().split(" ");
			var first = text.shift();
			return "<b>"+first+"</b> " + text.join(" ");
		},
		montarServico: function() {
			this.servico = this.$parent.servicosData[this.servicoID]
			this.dataReady = true
			if( Object.keys(this.servico.main_image.path).length !== 0) { this.mainBG = true }
			this.changeMetaData()
		}
	}
};
</script>